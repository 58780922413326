import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import APIPortals from "../components/api-portals"
import CTA from "../components/cta"

const IndexPage = () => (
  <Layout detachedHeader>
    <SEO title="Home" />
    <Hero title={`The FireEye Developer Hub`}>
      <p>
        Everything you need to integrate with our products. Explore one of our
        APIs below to get started.
      </p>
    </Hero>
    <APIPortals />
    <CTA />
  </Layout>
)

export default IndexPage
