import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import APIPortal from "./api-portal"

import "./api-portals.scss"

const APIPortals = () => (
  <Container fluid className="api-portals">
    <Row noGutters className="max-width">
      <APIPortal
        image="dod"
        title="Detection On Demand"
        description="Detonate malicious files and URLs"
        toSpec="/apis/detection-on-demand/"
        toDocs="/docs/detection-on-demand/"
        lg={{ size: 6 }}
      />
      <APIPortal
        image="endpoint"
        title="Endpoint Security"
        description="Query and act on Endpoint data"
        toSpec="/endpoint-apis/"
        toDocs="/docs/endpoint/"
        toSpecText="Explore APIs"
        lg={{ size: 6 }}
      />
      <APIPortal
        image="helix"
        title="Helix"
        description="Security operations platform"
        toSpec="/apis/helix/"
        toDocs="/docs/helix/"
        lg={{ size: 6 }}
      />
    </Row>
  </Container>
)

export default APIPortals
