import React from "react"

import "./cta.scss"

const CTA = () => {
  return (
    <div className="cta no-gutters">
      <span className="cta--text">
        Join the FireEye Developer Community to stay connected.
      </span>
      <a
        className="btn btn--lg btn--hollow"
        href="https://community.fireeye.dev"
        target="_blank"
        rel="noopener noreferrer"
      >
        Join the Community
      </a>
    </div>
  )
}

export default CTA
